<template>
  <div class="notice">
    <!-- <el-empty description="暂无内容"></el-empty> -->
    <div class="content">
      <vue-office-docx :src="docx" @rendered="rendered" />
    </div>
  </div>
</template>

<script>
import VueOfficeDocx from "@vue-office/docx"

export default {
  components: {
    VueOfficeDocx,
  },
  data() {
    return {
      docx: "https://xn--fcs331b8yd3xj5in.com/files/docx/test.docx",
    }
  },
  created() {},
  methods: {
    rendered() {
      console.log("渲染完成")
    },
  },
}
</script>

<style scoped lang="scss">
.notice {
  display: flex;
  justify-content: center;
  min-width: 1000px;
  margin: 0 auto;
  .content {
    flex: 1;
  }
  ::v-deep .docx-wrapper {
    background-color: transparent;
  }
  ::v-deep .docx {
    padding-top: 0 !important;
    box-shadow: none;
  }
}
</style>
